.msg {
    display: flex;
    align-items: center;
    font-size: 14px;
    &--error {
      color: #c04f4f;
    }
    &--success {
      color: #538e55;
    }
}
